/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202410172343-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface FormulaErrorOutputV1 {
    /**
     * The column of the formula that resulted in an error
     */
    "column"?: number;
    /**
     * The category of the formula error, i.e. when it was encountered
     */
    "errorCategory"?: ErrorCategoryEnum;
    /**
     * The type of formula error that occurred
     */
    "errorType"?: ErrorTypeEnum;
    /**
     * The line of the formula that resulted in an error
     */
    "line"?: number;
    /**
     * An error message for the compiled formula
     */
    "message"?: string;
    /**
     * A plain language status message with information about any issues that may have been encountered during an operation. Null if the status message has not been set.
     */
    "statusMessage"?: string;
}

export enum ErrorCategoryEnum {
    SYNTAX = 'SYNTAX' as any,
    METADATA = 'METADATA' as any,
    RUNTIME = 'RUNTIME' as any,
    UPSTREAM = 'UPSTREAM' as any
}
export enum ErrorTypeEnum {
    SYNTAXERROR = 'SYNTAX_ERROR' as any,
    MAXDURATIONREQUIRED = 'MAX_DURATION_REQUIRED' as any,
    MAXDURATIONPROHIBITED = 'MAX_DURATION_PROHIBITED' as any,
    METADATAERROR = 'METADATA_ERROR' as any,
    DATASPECERROR = 'DATA_SPEC_ERROR' as any,
    RUNTIMEERROR = 'RUNTIME_ERROR' as any,
    INCOMPATIBLEUNITS = 'INCOMPATIBLE_UNITS' as any,
    CONTINUOUSREQUIRED = 'CONTINUOUS_REQUIRED' as any,
    PROPERTYERROR = 'PROPERTY_ERROR' as any,
    LOCALLYCONSTANT = 'LOCALLY_CONSTANT' as any,
    ILLEGALVALUE = 'ILLEGAL_VALUE' as any,
    ILLEGALPARAMETER = 'ILLEGAL_PARAMETER' as any,
    UPSTREAMERROR = 'UPSTREAM_ERROR' as any,
    FUNCTIONNOTFOUNDERROR = 'FUNCTION_NOT_FOUND_ERROR' as any
}
